import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Fade from '../../components/Fade';
import Form from '../../components/Games/Form';
import { getGame } from '../../store/actions';

function FormWin() {
  const history = useHistory();
  const dispatch = useDispatch();
  const wins = useSelector(state => state.user.user?.wins || []);
  const lang = useSelector(state => state.app.config?.lang);
  const { game } = useSelector(state => state.promo || {});
  const { log, played, form } = game.length > 0 ? game[0] : {};

  const [winFound, setWinFound] = useState();

  useEffect(() => {
    if (wins.length > 0) {
      const elFound = wins.find(win => win.pending);
      if (elFound) {
        setWinFound(elFound);
        dispatch(getGame(elFound.game_id, 'geberit', ''));
      } else {
        history.push('/');
      }
    }
  }, [wins]);

  return (
    <Fade>
      {game.length > 0 && (
        <Form
          form={form}
          formResponse={log.form}
          lang={lang}
          id={winFound.game_id}
          played={played}
          formWin
          playslog={winFound.id}
        />
      )}
    </Fade>
  );
}

export default FormWin;
