import React from 'react';
import Helmet from 'react-helmet';

const Seo = props => {
  const { title, description, keywords, image } = props;

  return (
    <Helmet>
      <meta name='description' content={`${description}`} />
      <meta name='keywords' content={`${keywords}`} />
      <title>{`${title}`}</title>
      <meta name="robots" content="noindex" />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={`${title}`} />
      <meta property='og:description' content={`${description}`} />
      {image ? <meta property='og:image' content={`${image}`} /> : ''}
    </Helmet>
  );
};

export default Seo;
