import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MeetingRoom } from '@styled-icons/material-twotone';
import { respondTo } from '../../theme/mixin';

const LogoutContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 31px;
    ${respondTo.sm`
      width: 20px;
    `};
    ${respondTo.md`
      width: 31px;
    `};
  }
  span {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 8px;

    ${respondTo.sm`
      font-size: 12px;
    `}
    ${respondTo.md`
      font-size: 14px;
    `};
  }
`;

const Logout = props => {
  const { label } = props;

  return (
    <LogoutContainer {...props}>
      <span>{label}</span>
      <MeetingRoom />
    </LogoutContainer>
  );
};

Logout.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
};

export default Logout;
