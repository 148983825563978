import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Wrapper, Logo, Flex, Text } from '../Ui';
import NavigationItems from '../Navigation/NavigationItems/NavigationItems';
import MenuLateral from '../MenuLateral';
import { respondTo } from '../../theme/mixin';
import logoTest from '../../assets/images/logo.png';
const HeaderSection = styled.header`
    border-top: 5px solid ${props => props.theme.primary};
    background-color: #fff;
    box-shadow: 0px 5px 15px #00000026;
    border-radius: 0 0 10px 10px;
    position: fixed;
    top: 0;
    left: 15px;
    z-index: 9;
    width: calc(100% - 30px);

    ${respondTo.sm`
    margin-left: 0;
    width: 100%;
    position: static;
    margin-bottom: 30px;
  `}
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 80px;
        /*  min-height: ${props => props.theme.header_mobile_height + 'px'}; */

    ${respondTo.sm`
    min-height: ${props => props.theme.header_desktop_height + 'px'};
    gap: 10px;
  `}
    ${respondTo.md`
    min-height: ${props => props.theme.header_desktop_height + 'px'};
    gap: 100px;
  `}
`;

const LogoNavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    flex: 1;
    .classifica {
        border-bottom: 2px solid #eaf0f3;
        ${respondTo.sm`
      margin-right:20px;
      text-align:center;
      border-bottom:none;
	`}
        ${respondTo.md`
      margin-right:20px;
   
	`}
        .point {
            ${respondTo.sm`
     
     
	`}
            ${respondTo.md`
     
	`}
        }
    }
    .text-classifica {
        margin-right: 25px;
    }
    .text {
        width: 80px;
        flex: 1;
        padding: 15px 0;

        ${respondTo.sm`
      flex:initial
      padding:initial;
	`}
        .point {
            ${respondTo.sm`
      font-size:12px;
	`}
            ${respondTo.md`
      font-size:14px;
	`}
        }
        .text-point {
            ${respondTo.sm`
      font-size:9px;
	`}
            ${respondTo.md`
      font-size:10px;
	`}
        }
    }
`;

const NavContainer = styled.div`
    display: ${props => (props.isOpen ? 'block' : 'none')};
    position: absolute;
    top: 83px;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #dce4e8;
    box-shadow: 0px 5px 15px #00000026;
    border-radius: 0 0 10px 10px;

    ${respondTo.sm`
  	display: flex;
  flex:1;
  position: static;
    justify-content: space-between;
    margin-left: 20px;
    align-items: center;
    width: initial;
    top: 0;
   
    border-top: none;
    box-shadow: none;

	`}

    ${respondTo.md`   
      margin-left:70px; 
    `}
`;

const Header = props => {
  const { logo, logout, isLogged, multipromo, i18l } = props;

  const games = useSelector(state => state.game.games);
  const { week_position, general_position } = useSelector(
    state => state.user.user?.rankings?.[0] || {},
  );
  const [idGame, setIdGame] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (games.length > 0) {
      setIdGame(games[0].id);
    }
  }, [games]);

  return (
    <HeaderSection>
      <Wrapper>
        <HeaderContainer>
          <LogoNavContainer>
            <Logo logo={logo} />

            <NavContainer isOpen={isMenuOpen}>

              <NavigationItems
                isLogged={isLogged}
                multipromo={multipromo}
                i18l={i18l}
                idGame={idGame}
                logout={logout}
              />
            </NavContainer>
          </LogoNavContainer>
          <MenuLateral
            {...props}
            toggleMenu={() => setIsMenuOpen(!isMenuOpen)}
            isMenuOpen={isMenuOpen}
          />
        </HeaderContainer>
      </Wrapper>
    </HeaderSection>
  );
};

Header.propTypes = {
  logo: PropTypes.string,
  appInit: PropTypes.func,
  logout: PropTypes.func,
  isLogged: PropTypes.string,
  appConfig: PropTypes.object,
};

export default Header;
