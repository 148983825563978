import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Flex, Text } from '../../components/Ui';

const AdvantageBox = ({ partner, linked, single }) => {
  return (
    <AdvantageBoxWrapper single={single}>
      <Link to={`/advantage/${partner.id}`}>
        <div className='advantage-image'>
          <img src={partner.main_image} alt='' />
        </div>

        {!single && (
          <>
            <div className='advantage-info'>
              <Text white upper bold size={14} align='center'>
                {partner.name}
              </Text>
            </div>

            <div className='advantage-title'>
              <Text upper bold primary size={14} align='center'>
                {partner.title}
              </Text>
            </div>
          </>
        )}
      </Link>
    </AdvantageBoxWrapper>
  );
};

const AdvantageBoxWrapper = styled.div`
  border-radius: 10px;
  box-shadow: 0px 2px 10px #00000026;
  overflow: hidden;
  margin-bottom: 30px;
  height: 100%;
  ${({ single }) =>
    !single &&
    css`
      &:hover {
        .advantage-image:after {
          height: 100%;
          transition: all 0.4s;
        }
      }
    `}

  .advantage-image {
    height: ${({ single }) => !single && '240px'};
    background-color: rgba(0, 0, 0, 0.3);
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      background-color: ${props => props.theme.primary};
      transition: all 0.4s;
    }
  }

  .advantage-info {
    background-color: ${props => props.theme.primary};
    padding: 5px 0;
  }

  .advantage-title {
    padding-top: 7px;
    padding-bottom: 15px;
    background-color: #fff;
    position: relative;

    .advantage-logo {
      position: absolute;
      top: -20px;
      left: 10px;
      width: 76px;
      height: 76px;
      border-radius: 50%;
      box-shadow: 0px 2px 10px #00000026;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;

export default AdvantageBox;
