import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Text } from '../Ui';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { KeyboardArrowUp } from '@styled-icons/material';
import parse from 'html-react-parser';
import lofoFooter from '../../assets/images/logoFooter.png';
import { ReactComponent as Dialog } from '../../assets/images/icon-dialog.svg';
import { useSelector } from 'react-redux';

const FooterContainer = styled.footer`
  margin-top: 30px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  box-shadow: 0px -5px 15px #00000026;
`;

const TopBar = styled.div`
  background-color:  ${({ theme }) => theme.primary};
  padding: 15px 0 10px 0;
  text-align: center;

  img {
    max-width: ${props => props.theme.footer_logo_size}px;
  }
`;

const BottomBar = styled.div`
  background-color:  ${({ theme }) => theme.secondary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  .dialog {
    width: 20px;
    height: 20px;
  }

  img {
    max-width: 20px;
    height: auto;
      width: 400px;
  }

  .text {
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    p {
      padding: 0 3px;
    }

    a {
      font-size: 8px;
      padding: 0 3px;
      color: ${({ theme }) => theme.primary};
      text-decoration: underline;
      font-weight: bold;
    }
  }
`;

const IconUp = styled(KeyboardArrowUp)`
  cursor: pointer;
  color: ${props => props.theme.primary};
  flex: 0 0 30px;
`;

const Footer = props => {
  const { footercontent } = props;

  // Get info from CMS section
  const footerlogo = footercontent[0].contents.find(el => el.name === 'logo');
  const footertext = footercontent[0].contents.find(el => el.name === 'text');
  const isLogged = useSelector(state => state.auth.token);
  return (
    <Wrapper>
      <FooterContainer>
        <TopBar>
          <img src={footerlogo?.img} alt='' />
        </TopBar>

      </FooterContainer>
    </Wrapper>
  );
};

Footer.defaultProps = {
  content: '',
};

Footer.propTypes = {
  footercontent: PropTypes.array,
};

export default Footer;
