import React, { useState } from 'react';
import styled from 'styled-components';
import InputField from './InputField/InputField';
import Select from './Select/Select';
import Checkbox from './Checkbox/Checkbox';
import Textarea from './Textarea/Textarea';
import Datepicker from './Datepicker';
import File from './File';
import Radio from './Radio';
import InputPrice from './InputPrice';
import InputTime from './InputTime';

const InputContainer = styled.div`
  margin-bottom: 20px;
  display: ${props => (props.type === 'checkbox' ? 'flex' : 'block')};
`;

const ErrorContainer = styled.p`
  margin-top: 5px;
  text-align: right;
  color: red;
  font-size: 12px;
`;

const TextP = styled.p`
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 8px;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  margin-top: 24px;
`;

const Input = props => {
  let label = null;
  let inputElement = null;
  let title = null;

  const [startDate, setStartDate] = useState(null);

  if (props.title) {
    title = <TextP {...props}>{props.title}</TextP>;
  }

  switch (props.elementType) {
    case 'input':
      inputElement = (
        <InputField
          {...props.elementConfig}
          onChange={props.changed}
          {...props}
          value={props.value}
          readOnly={props.readonly}
        />
      );
      break;
    case 'select':
      let options = null;
      if (props.isRegions) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.regions.map(opt => (
              <option value={opt.regione_slug} key={opt.regione}>
                {opt.regione}
              </option>
            ))}
          </>
        );
        //} else if (props.elementConfig.placeholder === 'Provincia') {
      } else if (props.isProvince) {
        options = (
          <>
            {props.elementConfig.options?.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.provinces.map(opt => (
              <option value={opt.value} key={opt.id}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } else if (props.isDistrict) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.districts.map(opt => (
              <option value={opt.provincia_slug} key={opt.provincia}>
                {opt.provincia}
              </option>
            ))}
          </>
        );
      } else if (props.isCity) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.cities.map(opt => (
              <option value={opt.name} key={opt.name}>
                {opt.name}
              </option>
            ))}
          </>
        );
      } else if (props.isPdv) {
        options = (
          <>
            {props.elementConfig.options.map(opt => (
              <option value={opt.value} key={opt.value} disabled={opt.disabled}>
                {opt.displayValue}
              </option>
            ))}
            {props.pdv.map(opt => (
              <option value={opt.pdv} key={opt.pdv}>
                {opt.pdv}
              </option>
            ))}
          </>
        );
      } else {
        options = props.elementConfig.options.map(opt => (
          <option value={opt.value} key={opt.value}>
            {opt.displayValue}
          </option>
        ));
      }
      inputElement = (
        <Select
          onChange={props.changed}
          name={props.elementConfig.name}
          disabled={props.elementConfig.disabled}
          className='select-gender'
          {...props}
        >
          {options}
        </Select>
      );
      break;
    case 'datepicker':
      inputElement = (
        <Datepicker
          lang={props.lang}
          {...props.elementConfig}
          selected={props.startdate}
          onChange={props.changedDatePicker}
          minDate={
            props.validation.minDate
              ? props.validation.minDate === 'today'
                ? new Date()
                : new Date(props.validation.minDate)
              : null
          }
          {...props}
          maxDate={
            props.validation.maxDate
              ? props.validation.maxDate === 'today'
                ? new Date()
                : new Date(props.validation.maxDate)
              : null
          }
          {...props}
        />
      );
      break;
    case 'inputcheckbox':
      inputElement = (
        <Checkbox
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          onClick={() => (props.readonly ? 'return false;' : '')}
          {...props}
        />
      );
      break;
    case 'inputradio':
      inputElement = (
        <Radio
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          inputs={props.radioInputs}
          readonly={props.readonly}
          {...props}
        />
      );
      break;
    case 'inputfile':
      inputElement = (
        <File
          {...props.elementConfig}
          handleFile={props.fileUploadHandler}
          imagePreviewUrl={props.imagePreviewUrl}
          deleteFileHandler={props.deleteFileHandler}
          value={props.value}
          {...props}
        />
      );
      break;
    case 'price':
      inputElement = (
        <InputPrice
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          {...props}
        />
      );
      break;
    case 'inputtime':
      inputElement = (
        <InputTime
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          {...props}
        />
      );
      break;
    case 'textarea':
      inputElement = (
        <Textarea
          {...props.elementConfig}
          onChange={props.changed}
          value={props.value}
          {...props}
        />
      );
      break;
    default:
      inputElement = <input />;
  }

  return (
    <React.Fragment>
      {title}
      <InputContainer type={props.elementConfig.type}>
        {label ? label : null}
        {inputElement}
        <ErrorContainer>
          {(!props.isvalid && !props.istouched && props.firstSubmit && props.showError) ||
          (!props.isvalid && props.showError)
            ? props.error
            : ''}
        </ErrorContainer>
      </InputContainer>
    </React.Fragment>
  );
};

export default Input;
